import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  EditIcon,
  InputField,
  LoaderIcon,
  Pagination,
  SearchIcon
} from "@bloomreach/react-banana-ui";
import JsonView from "@uiw/react-json-view";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import "@bloomreach/react-banana-ui/style.css";
import { getSearchResults, getSuggestions } from "./api";
import ClarityAvatarIcon from "./assets/clarity-avatar-2.svg";
import ClaritySendEnabledIcon from "./assets/clarity-send-enabled.svg";
import ClaritySendIcon from "./assets/clarity-send.svg";
import { Price } from "./components/price";
// import { account_id, account_name } from "./config";

import "./app.css";

let dev = false
// dev = true

const configurations = {
  production: {
    projectId: '98e1934c-670c-11ef-b164-1a987cbfc69c',
    personaId: 'b80f9932-07ea-4fca-a4b6-8c960747f90f',
    url: 'https://cloud-api.exponea.com/'
    // projectId: 'c69e301a-1db0-11ef-b3fa-6e85f3b38550',
    // personaId: '8d4874be-f062-4ea0-ae53-07497765aacb',
    // url: 'https://api-analytics.master.gdev.exponea.com'
  },
  dev: {
    projectId: '27b89164-4b03-11ee-b4b1-7ef7fb60c440',
    personaId: '2c509912-80d3-4be8-b3bf-e06d864bbe5e',
    url: 'http://localhost:3333',
  }
}

const config = dev ? configurations.dev : configurations.production

function escapeFilterQuery(value) {
  return value.split('"').join('\\"');
}

const attrWhiteList = ['condition', 'brand', 'price', 'pattern', 'countryOfOrigin', 'flavor', 'rating', 'color', 'size', 'category']
const skuAttrMapping = {
  color: 'sku_color',
  size: 'sku_size',
  price: 'sku_price',
}

const deduplicateArray = (arr) => {
  const res = Array.from(new Set(arr.map(a => typeof a === 'string' ? a.trim() : a.displayText?.trim())))
  return res
}

const extractFQ = (query) => {
  const fq = {};
  const must = query?.claritySearchFilter?.must
  if (must) {
    for (const item of must) {
      if (!attrWhiteList.includes(item.key)) {
        continue
      }
      // const key = skuAttrMapping[item.key] || item.key
      const key = item.key
      if (item.match) {
        if (item.match.value) {
          fq[key] = `"${item.match.value}"`
        } else if (item.match.any) {
          fq[key] = item.match.any.map(v => `"${escapeFilterQuery(v)}"`).join('OR')
        }
      } else if (item.range) {
        if (item.range.gt) {
          fq[key] = `[${Number(item.range.gt) + 0.01} TO *]`
        } else if (item.range.gte) {
          fq[key] = `[${item.range.gte} TO *]`
        } else if (item.range.lt) {
          fq[key] = `[* TO ${Number(item.range.lt) - 0.01}]`
        } else if (item.range.lte) {
          fq[key] = `[* TO ${item.range.lte}]`
        }
      }
    }
  }
  return Object.keys(fq).length > 0 ? fq : undefined
}

const parseFQ = (fq) => {
  if (!fq) {
    return
  }
  const s = JSON.stringify(fq)
  return s.replaceAll('\\"', '===').replaceAll('"', '').replaceAll('===', '"')
}

function Loading(props) {
  return <div
    id="clarity-loading"
    style={{
      display: 'flex',
      gap: '0.5rem',
      height: '24px',
      alignItems: 'center',
      padding: '4px 0',
    }}

    {...props}
  >
    <div className="clairty-loading-dot"></div>
    <div className="clairty-loading-dot"></div>
    <div className="clairty-loading-dot"></div>
  </div>
}

function Content() {
  // const [showJson, setShowJson] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState('');
  // const [query, setQuery] = useState('chair');
  const [searchedQuery, setSearchedQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const suggestionsRef = useRef([])
  const [data, setData] = useState(null);
  const [sort, setSort] = useState("");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const [inputEditable, setInputEditable] = useState(true);
  const [clarityLoading, setClarityLoading] = useState(false);
  const [clarityRes, setClarityRes] = useState(null);
  const [clarityShown, setClarityShown] = useState(null);
  const clarityResRef = useRef(null)
  const clarityUpdateNotFromInit = useRef('')
  const clarityInputRef = useRef(null)
  const [clarityInputValue, setClarityInputValue] = useState('')
  const [clarityTextExpanded, setClarityTextExpanded] = useState(false)
  const queryUpdatedFromSuggestion = useRef(false)
  const enterToSearchRef = useRef(false)
  const structuredRef = useRef(null)
  const [selectedSuggestion, setSelectedSuggestion] = useState(0)

  const [debugOn, setDebugOn] = useState(false)

  const [tmpFQ, setTmpFQ] = useState('')

  const debouncedSuggestions = useMemo(() => _.debounce(searchSuggestions, 300), []);

  // for another version
  const vParamRef = useRef('');

  useEffect(() => {
    const main = (projectId, personaId) => {
      const PARAM_showAfter = 0;

      if (!window.CLARITY_CONFIG) {
        console.warn("Injected default integration snipped!");
        window.CLARITY = {
          _tempAsyncQueue: [],
          call: (name, arg) =>
            new Promise((res, rej) =>
              window.CLARITY._tempAsyncQueue.push({ name, arg, res, rej })
            ),
        };

        window.CLARITY_CONFIG = {
          url: undefined, // we want loader to find them based on current script path
          staticFilesUrl: undefined,
          projectId: projectId, // "27b89164-4b03-11ee-b4b1-7ef7fb60c440",
          //personaId: '2c509912-80d3-4be8-b3bf-e06d864bbe5e',
          personaId: personaId, // "e11f4c90-433b-4c38-b549-da07c9501845",
          getCurrentCustomerId: () =>
            window.exponea?.configuration?.customer?.cookie ??
            `TEST_UI_USER_ID_${Math.random()}`,
          defaultCurrency: "EUR",
          parentElement: '#clarity-container',
          ui: {
            debugOption: true,
            //color: "#0000FF",
            //theme: "",
            //background: "#00FF00"
            //position: ""
          },
          // fullMode: true,
        };

        const url = config.url;
        const loaderUrl = `${url}/cocoaas/public/bundle/index_cocoChatLoader.js?t=${Date.now()}`;
        const script = document.createElement("script"); // defer
        script.src = loaderUrl;

        document.body.appendChild(script);

        setTimeout(function () {
          window.CLARITY.call("show_badge");
          window.CLARITY.call("open_chat");

          //var productIds = stringToArray(PARAM_productIds);
          // window.CLARITY.call("ADD_MESSAGE.ASSISTANT.COLD_START", {
          //   text: PARAM_initialMessage,
          // });
        }, PARAM_showAfter * 1000);
      }
    }

    main(config.projectId, config.personaId)

    const searchParams = new URLSearchParams(window.location.search);
    const v = searchParams.get('v');
    vParamRef.current = v
    setDebugOn(searchParams.get('debug') === 'true')

  }, [])


  useEffect(() => {
    const getContext = () => {
      return { currentItemIds: data?.response?.docs?.map(product => product.pid || product.variants?.[0].skuid) }
    }
    window.CLARITY_CONFIG.getContext = getContext
    if (data) {
      if (clarityUpdateNotFromInit.current) {
        claritySummarize(query, clarityUpdateNotFromInit.current)
      } else {
        claritySearch(query)
      }
    }
  }, [data])

  const claritySummarize = useCallback(async (input, source) => {
    setClarityLoadingState(true)
    // const res = await window.CLARITY.call("user_summarizer_message", { input, invisible })
    const res = await window.CLARITY.call("user_direct_call", { input, invisible: source !== 'DISCOVERY_SUGGESTION', target: 'results_summarizer', source })
    setClarityLoadingState(false)
    let comparison, summary, question
    let qr
    for (const msg of res) {
      if (msg.type ===
        'ADD_MESSAGE.ASSISTANT.TEXT' || msg.type ===
        'APPEND_LAST_ASSISTANT_MESSAGE') {
        if (msg.marker === 'RS_SUMMARY') {
          summary = msg.text
        } else if (msg.marker === 'RS_COMPARISON') {
          comparison = msg.text
        } else if (msg.marker === 'RS_QUESTION') {
          question = msg.text
        }
      }
      if (msg.type === 'ADD_MESSAGE.ASSISTANT.QUICK_REPLY') {
        qr = { text: msg.text, data: msg.data }
      }
    }
    if (clarityShown === null || clarityRes) {
      setClarityRes({ query, summary, comparison, question, qr })
    } else {
      clarityResRef.current = { query, summary, comparison, question, qr }
    }
  }, [clarityShown, clarityRes])

  const clarityRephrase = async (input, hideLoding = true) => {
    setClarityLoadingState(true)
    const res = await window.CLARITY.call("user_direct_call", { input, target: 'sqr_with_search_or_extraction', source: clarityUpdateNotFromInit.current || 'DISCOVERY_SUGGESTION', invisible: !clarityUpdateNotFromInit.current })
    // const res = await window.CLARITY.call("user_rephase_message", { input })
    if (!res?.length && hideLoding) {
      setClarityLoadingState(false)
    }
  }

  const setClarityLoadingState = (loading) => {
    if (loading) {
      const display = document.getElementById('clarity-wrapper').style.display
      if (display === 'block') {
        return
      }
    }
    setClarityLoading(loading)
  }

  useEffect(() => {
    if (clarityRes) {
      clarityResRef.current = clarityRes
      if (clarityShown === null) {
        setClarityShown(Number(vParamRef.current) === 2)
      }
    }
  }, [clarityRes])

  useEffect(() => {
    if (clarityShown !== null) {
      if (clarityShown) {
        setClarityRes(null)
        window.CLARITY.call("open_chat");
        setTimeout(() => {
          document.getElementById('cocoaas_chat_input')?.focus()
        }, 100);
      } else {
        setClarityRes(clarityResRef.current)
      }
    }
  }, [clarityShown])

  useEffect(() => {
    if (queryUpdatedFromSuggestion.current) {
      if (query?.length > 1) {
        debouncedSuggestions(query);
        // search(query, page, perPage, sort);
      } else {
        setSuggestions(getDefaultSuggestion(query));
        // setData({});
      }
    }

    return () => {
      debouncedSuggestions.cancel();
    };
  }, [query, debouncedSuggestions]);

  function getDefaultSuggestion(queryText) {
    return queryText ? [queryText] : []
  }

  useEffect(() => {
    search(query, page, perPage, sort);
  }, [page, perPage, sort]);

  async function claritySearch(text) {
    if (text && window.CLARITY?.call) {
      toggleClarity(Number(vParamRef.current) === 2)
      const cb = async () => {
        await clarityRephrase(text, false)
        claritySummarize(text, 'DISCOVERY_SUGGESTION')
        delete window.CLARITY_CONFIG.hooks.initialized
      }
      const hooks = {
        initialized: cb,
        searchUpdated: (queryText, structured, source) => {
          structuredRef.current = structured
          if (queryText !== query) {
            setInputEditable(false)
          }
          if (source !== 'DISCOVERY_SUGGESTION') {
            clarityUpdateNotFromInit.current = source
            updateSearch(queryText)
          }
        },
        badgeHidden: v => toggleClarity(v),
        chatClosed: v => toggleClarity(v)
      }
      window.CLARITY_CONFIG.hooks = { ...(window.CLARITY_CONFIG.hooks || {}), ...hooks }
      await window.CLARITY.call('delete_chat')
      setClarityLoadingState(true)
    }
  }

  const search = (queryText, page, perPage, sort) => {
    if (!queryText) {
      return
    }
    setLoading(true);
    setError(null);
    const fq = extractFQ(structuredRef.current)
    getSearchResults(queryText, page, perPage, sort, fq)
      .then((response) => {
        if (response.response.numFound === 0 && Object.keys(fq).length) {
          getSearchResults(queryText, page, perPage, sort, undefined)
            .then((response) => {
              setLoading(false);
              setData(response);
              setSearchedQuery(response.autoCorrectQuery || queryText)
              setTmpFQ(parseFQ(fq) + " (ignored because of no results)")
            })
            .catch((error) => {
              setLoading(false);
              console.error("Error fetching results:", error);
              setData(null);
              setError(error);
              setTmpFQ(parseFQ(fq) + " (ignored because of no results)")
            });
        } else {
          setLoading(false);
          setData(response);
          setSearchedQuery(response.autoCorrectQuery || queryText);
          setTmpFQ(fq ? parseFQ(fq) : '')
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching results:", error);
        setData(null);
        setError(error);
        setTmpFQ(fq ? parseFQ(fq) : '')
      });
  }

  function searchSuggestions(queryText) {
    // setLoading(true);
    setError(null);
    getSuggestions(queryText)
      .then((response) => {
        // setLoading(false);
        const results = response.suggestionGroups[0];
        if (!enterToSearchRef.current) {
          setSuggestions(deduplicateArray([...getDefaultSuggestion(queryText), ...(_.take(results.querySuggestions, 6))]));
          enterToSearchRef.current = false
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.error("Error fetching suggestions:", error);
        if (!enterToSearchRef.current) {
          setSuggestions(getDefaultSuggestion(queryText));
          enterToSearchRef.current = false
        }
        setError(error);
      });
  }

  const suggestionClicked = async (suggestion) => {
    enterToSearchRef.current = true
    setSuggestions([]);
    setTmpFQ('')
    structuredRef.current = null
    clarityUpdateNotFromInit.current = ''
    updateSearch(suggestion)
  }

  function updateSearch(newQuery) {
    if (newQuery === query && clarityUpdateNotFromInit.current) {
      claritySummarize(query, clarityUpdateNotFromInit.current)
      return;
    }
    setPage(0);
    setSort("");
    queryUpdatedFromSuggestion.current = false
    setQuery(newQuery);

    // clarityUpdateNotFromInit.current = notFromInit
    search(newQuery, page, perPage, sort);
  }

  const queryChanged = (queryText) => {
    setClarityRes(null)
    setClarityShown(null)
    setPage(0);
    setSort("");
    enterToSearchRef.current = false
    queryUpdatedFromSuggestion.current = true
    setQuery(queryText);
    setData(null)
  }

  const QRClicked = (label) => {
    clarityUpdateNotFromInit.current = 'DISCOVERY_QR'
    clarityRephrase(label)
  }

  const sendMessage = () => {
    const match = clarityRes.qr.data.find(qr => qr.label.toLowerCase() === clarityInputRef.current.value.trim().toLowerCase())
    if (match) {
      QRClicked(match.label)
    } else {
      clarityUpdateNotFromInit.current = 'DISCOVERY'
      clarityRephrase(clarityInputRef.current.value)
    }
  }

  const handleClarityInput = (e) => {
    setClarityInputValue(e.target.value)
    if (e.key === 'Enter') {
      sendMessage()
    }
  }

  const toggleClarity = (open = false) => {
    setClarityShown(prev => {
      if (prev === null) {
        return Number(vParamRef.current) === 2 ? open : prev
      } else {
        return open
      }
    })
  }

  const toggleClarityText = () => {
    setClarityTextExpanded(prev => !prev)
  }

  const onInputKeyPressed = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      suggestionClicked(selectedSuggestion === 0 ? e.target.value : suggestions[selectedSuggestion])
    } else if (e.key === 'ArrowUp' || e.keyCode === 38) {
      e.preventDefault()
      setSelectedSuggestion(prev => (prev - 1) < 0 ? suggestions.length - 1 : (prev - 1))
    } else if (e.key === 'ArrowDown' || e.keyCode === 40) {
      e.preventDefault()
      setSelectedSuggestion(prev => (prev + 1) % suggestions.length)
      // } else if (e.key === 'Tab' || e.keyCode === 9) {
      //   e.preventDefault()
      //   queryChanged(suggestions[selectedSuggestion].displayText)
    }
  }

  useEffect(() => {
    if (suggestions.length) {
      suggestionsRef.current = suggestions
    } else if (enterToSearchRef.current) {
      suggestionsRef.current = []
    }
  }, [suggestions])

  // const toggleRef = useRef()
  // useEffect(() => {
  //   if (toggleRef.current !== toggleClarity) {
  //     toggleRef.current = toggleClarity;
  //     const hooks = {
  //       badgeHidden: v => toggleClarity(v),
  //       chatClosed: v => toggleClarity(v)
  //     }
  //     window.CLARITY_CONFIG.hooks = { ...(window.CLARITY_CONFIG.hooks || {}), ...hooks }
  //   }
  // }, [toggleClarity]);

  return (
    <>
      <div className="min-h-screen flex-1 flex flex-row place-content-center">
        <div className="flex-1">
          <div>
            <div className="app p-2 max-w-5xl w-full mx-auto grow">
              <div className="flex gap-2 items-center mt-8 mb-8">
                <div className="flex gap-2 items-center grow">
                  <a href="https://sandbox-product03.bloomreach.com/home" target="_blank">
                    <img src="https://sandbox-product03.bloomreach.io/delivery/resources/content/gallery/pacific-home/logos/pacific-home.png" width={150} />
                  </a>
                </div>
              </div>

              <div>
                <div className="relative">
                  <div className="flex gap-2 place-content-center items-center">
                    <InputField style={{ flex: 1 }}
                      helperText="Search for chair, bed, office furniture, kitchen tools, chiar (for autocorrect)..."
                      value={query}
                      leftElement={loading ? <LoaderIcon className="animate-spin" /> : <SearchIcon />}
                      clearable
                      fullWidth
                      disabled={!inputEditable}
                      onKeyUp={(e) => onInputKeyPressed(e)}
                      onChange={(e) => queryChanged(e.target.value)}
                      onBlur={() => () => setSuggestions([])}
                      onFocus={() => setSuggestions(suggestionsRef.current)}
                    >
                    </InputField>
                    {!inputEditable && (
                      <button className="border-0 px-[10px] rounded-full edit-button bg-transparent mb-[4px]" onClick={() => setInputEditable(true)}>
                        <EditIcon viewBox="-2 1 16 16"></EditIcon>
                      </button>
                    )}
                  </div>
                  <div style={{ zIndex: 1, top: 'calc(100% - 2px)' }} className={"suggestion-list bg-white absolute left-0 right-0 border-x border-[#bdcee3] border-solid " + (suggestions.length ? 'block' : 'hidden')}>
                    {suggestions.map((suggestion, index) => (
                      <div key={index} className={"suggestion-item overflow-hidden relative px-[12px] py-[8px] " + (index === selectedSuggestion ? "selected" : "")} onMouseDown={() => suggestionClicked(suggestion)}>
                        {/* <button className={"outline-none clarity-div-container px-[12px] py-[8px] gap-[8px] border-none flex place-content-center items-center " + (clarityLoading ? 'pointer-events-none' : '')} onClick={() => suggestionClicked(suggestion.displayText)}> */}
                        <span>{suggestion}</span>
                        {/* </button> */}
                      </div>
                    ))}
                  </div>
                </div>
                {loading && <div>Loading...</div>}
                {error && (
                  <div>
                    <h1 className="text-lg">Error: </h1>
                    <JsonView value={error} />
                  </div>
                )}
                {!clarityShown && (clarityLoading || clarityRes) && (data?.response?.docs?.length >= 12 || clarityRes?.summary) && (
                  //  pt-4 pb-3 pl-6 pr-6
                  <div className="mt-2 clarity-div-wrapper overflow-hidden relative w-full">
                    <div className="clarity-div-container flex flex-col gap-[16px] p-[16px]">
                      <div className="flex flex-row place-content-between items-center">
                        <div className="flex flex-row gap-[8px]">
                          <img src={ClarityAvatarIcon} className="w-[24px] min-w-[24px] h-[24px]"></img>
                          <span className="font-semibold" style={{ color: 'rgba(221, 111, 177, 1)' }}>AI Assistant</span>
                        </div>
                        {!clarityLoading && <button onClick={() => toggleClarity(true)} className="border-0 bg-transparent flex gap-[8px] items-center py-[4px] px-[12px]">
                          <span>Open conversation</span>
                          <ChevronRightIcon></ChevronRightIcon>
                        </button>}
                      </div>
                      {clarityLoading && <Loading className="ml-2 flex-1" />}
                      {!clarityLoading ? <>
                        {clarityRes?.summary && (<div className="flex flex-row place-content-between">
                          <Markdown className={"flex-1 clarity-markdown" + (clarityTextExpanded ? "" : " clarity-ellipsis")} remarkPlugins={[remarkGfm]}>{clarityRes.summary}</Markdown>
                          {clarityTextExpanded ? <ChevronUpIcon className="w-[24px] min-w-[24px] h-[24px] cursor-pointer" onClick={() => toggleClarityText()}></ChevronUpIcon> :
                            <ChevronDownIcon className="w-[24px] h-[24px] cursor-pointer" onClick={() => toggleClarityText()}></ChevronDownIcon>}
                        </div>)}
                        {!clarityLoading && data?.response?.docs?.length >= 12 && <>
                          {clarityRes.question && (
                            <Markdown remarkPlugins={[remarkGfm]}>{clarityRes.question}</Markdown>
                          )}
                          <div className="flex flex-wrap flex-row gap-[12px] items-center">
                            {clarityRes.qr && (<>
                              {clarityRes.qr.data.map((qr, index) => (
                                <button key={index} style={{ backgroundColor: 'rgba(141, 157, 185, .3)' }} className="text-black font-normal border-0 rounded-[8px] px-[12px] py-[4px]" onClick={() => QRClicked(qr.label)}>{qr.label}</button>
                              ))}
                              <button style={{ backgroundColor: 'rgba(141, 157, 185, .15)' }} className="text-black font-medium border-0 rounded-[8px] px-[12px] py-[4px]" onClick={() => QRClicked('No preference')}>No preference</button>
                            </>)}
                            <div className="flex-1 clarity-input-shadow min-w-[250px] max-w-[60%]">
                              <div className="clarity-input-wrapper">
                                <div className="clarity-input-container flex flex-row gap-[8px] items-center">
                                  <input placeholder="Type your response or enter a different filtering criteria" type="text" ref={clarityInputRef} className="px-[16px] pl-[8px] flex-1" onKeyUp={ev => handleClarityInput(ev)} />
                                  <button className={"p-0 bg-white border-none min-w-[24px] w-[24px] h-[24px]" + (clarityInputValue ? '' : ' pointer-events-none')} onClick={() => sendMessage()}>
                                    <img src={!!clarityInputValue ? ClaritySendEnabledIcon : ClaritySendIcon} className="w-[24px] h-[24px]"></img>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {clarityRes?.comparison && (<div className="flex flex-row place-content-between">
                            <Markdown className={"flex-1 clarity-markdown" + (clarityTextExpanded ? "" : " clarity-ellipsis")} remarkPlugins={[remarkGfm]}>{clarityRes.comparison}</Markdown>
                            {clarityTextExpanded ? <ChevronUpIcon className="w-[24px] min-w-[24px] h-[24px] cursor-pointer" onClick={() => toggleClarityText()}></ChevronUpIcon> :
                              <ChevronDownIcon className="w-[24px] h-[24px] cursor-pointer" onClick={() => toggleClarityText()}></ChevronDownIcon>}
                          </div>)}
                        </>}
                      </> : <></>}
                    </div>
                  </div>
                )}
                {(data?.response || data?.did_you_mean?.length || data?.autoCorrectQuery) && (<div className="flex gap-4">
                  <div className="w-full">
                    <div className="flex mt-2">
                      <div className="grow text-sm my-2">
                        {debugOn && <>Search results for{" "}
                          <span className="font-semibold">{searchedQuery}</span>
                          <div>{tmpFQ}</div></>}
                        {data?.did_you_mean?.length ? (
                          <div className="my-2">
                            Did you mean:{" "}
                            {data?.did_you_mean.map((term) => (
                              <span
                                key={term}
                                className="cursor-pointer px-2 mr-2 bg-blue-100 text-blue-600 hover:underline"
                                onClick={() => suggestionClicked(term)}
                              >
                                {term}
                              </span>
                            ))}
                          </div>
                        ) : null}
                      </div>

                      <div>
                        <select
                          className="p-2 rounded border text-sm"
                          value={sort}
                          onChange={(e) => setSort(e.target.value || "")}
                        >
                          <option value="">Relevance</option>
                          <option value="price asc">Price (low to high)</option>
                          <option value="price desc">Price (high to low)</option>
                          <option value="sale_price asc">
                            Sale Price (low to high)
                          </option>
                          <option value="sale_price desc">
                            Sale Price (high to low)
                          </option>
                        </select>
                      </div>
                    </div>

                    {data?.response?.docs?.length ? (
                      <div className="flex flex-col">
                        <div className="flex flex-row flex-wrap place-content-between">
                          {data?.response?.docs.map((product, index) => (
                            <div
                              className="m-2 w-56 shadow-md rounded-md border border-slate-100"
                              key={index}
                            >
                              <div className="flex flex-col gap-2">
                                <div className="w-full max-h-56 rounded-t-md overflow-hidden border-b border-slate-200 ">
                                  <img
                                    src={product.thumb_image}
                                    className="mr-2 w-full object-cover object-top"
                                  />
                                </div>
                                <div className="p-2 pt-0">
                                  <Highlighter
                                    className="w-full text-sm font-bold"
                                    searchWords={[searchedQuery]}
                                    textToHighlight={product.title}
                                  />
                                  <Price className="text-sm" product={product} />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {data?.response.numFound > 0 ? (
                          <div className="my-8">
                            <Pagination
                              count={data.response.numFound}
                              itemsPerPage={perPage}
                              itemsPerPageOptions={[12, 24, 48]}
                              onItemsPerPageChange={(newPerPage) =>
                                setPerPage(newPerPage)
                              }
                              onPageChange={(newPage) => setPage(newPage)}
                              page={page}
                            />
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="text-sm text-slate-500">NA</div>
                    )}
                  </div>
                </div>)}
              </div>
            </div>
          </div>
        </div>
        <div className="sticky top-0 hidden border-l-8 border-slate-100" id="clarity-wrapper" style={{ display: window.CLARITY_CONFIG?.parentElement && clarityShown ? 'block' : 'none' }}>
          <div className="relative h-full w-[470px]" id="clarity-container"></div>
        </div>
      </div>
    </>);
}



export default function App() {
  const [pwdPassed, setPwdPassed] = useState(false);

  useEffect(() => {
    const init = () => {
      fetch('https://m.radiancelabs.net/DemoConsole/API/Demo/Clarity?index=0', {
        method: 'GET',
        credentials: 'include'
      }).then(response => {
        if (response.status === 200) {
          return response.text()
        } else {
          document.querySelector('#content').style.display = 'block'
        }
      }).then(ids => {
        if (ids) {
          // const [projectId, personaId] = ids.split(',')
          // if (projectId && personaId) {
          //   main(projectId, personaId)
          // }
          setPwdPassed(true);
        }
      })
    }

    // !dev && init()
  }, [])


  const keyup = event => {
    if (event.key === 'Enter') {
      login()
    }
  }

  const login = () => {
    const url = 'https://m.radiancelabs.net/DemoConsole/API/Demo/Clarity';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({ pwd: document.querySelector('input[type=password]').value, index: 0 }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(response => {
      if (response.status === 200) {
        return response.text();
      } else {
        alert("Authentication Failed");
      }
    }).then(ids => {
      if (ids) {
        // const [projectId, personaId] = ids.split(',')
        // if (projectId && personaId) {
        //   document.querySelector('#content').remove()
        //   main(projectId, personaId)
        // }
        setPwdPassed(true);
      }
    }).catch(error => {
      alert("Authentication Failed");
    });
  };

  return <Content />

  return dev ? <Content /> : (pwdPassed ? <Content /> : <div id="content" style={{ display: 'none' }}>
    <span>Password:</span>
    <input type="password" onKeyUp={event => keyup(event)}></input>
    <button className="submit" type="submit" onClick={() => login()}>Submit</button>
  </div>)
}